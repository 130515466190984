<template>
  <ui-form
      @submit="handleSubmit"
      :submitText="$t('actions.save')"
      @cancel="handleCancel"
  >
    <ui-form-field :label="$t('fields.name')" rules="required">
      <el-input type="text" v-model="building.name"></el-input>
    </ui-form-field>

    <ui-form-field :label="$t('fields.description')">
      <el-input type="text" v-model="building.description"></el-input>
    </ui-form-field>

    <ui-form-field :label="$tc('models.company')" rules="required">
      <company-field v-model="building.company"></company-field>
    </ui-form-field>

    <ui-form-field :label="$t('labels.building.upload_background')">
      <el-upload
          action=""
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :limit="1"
          :on-exceed="handleExceed"
          :file-list="fileList"
          :on-change="setPicture"
          class="background_form_item">
        <el-button size="small" type="primary">{{ $t("actions.click_to_upload") }}</el-button>
        <div slot="tip" class="el-upload__tip"></div>
      </el-upload>
      <ui-button type="red" @click="setDeletedBackground" class="background_form_item delete_button"
                 v-if="backgroundExists">
        {{ $t(deleteBackgroundText) }}
      </ui-button>
    </ui-form-field>

    <el-row>
      <el-col :span="3">
        <ui-form-field style="margin-bottom: 20px" :label="$t('fields.send_reminder')"> </ui-form-field>
      </el-col>
      <el-col :span="3">
        <el-checkbox v-model="reminder"></el-checkbox>
      </el-col>
    </el-row>

    <el-card style="padding-left: 15px; margin-bottom: 20px;" v-if="reminder">
        <ui-form-field :label="$t('fields.first_reminder')">
          <el-input-number v-model="firstReminder"></el-input-number>
          <p v-if="msg.firstReminder" style="color: red">{{ msg.firstReminder }}</p>
        </ui-form-field>
        <ui-form-field style="margin-bottom: 10px" :label="$t('fields.second_reminder')">
          <el-input-number v-model="additionalReminder"></el-input-number>
          <p v-if="msg.additionalReminder" style="color: red">{{ msg.additionalReminder }}</p>
        </ui-form-field>
    </el-card>

  </ui-form>
</template>

<script>
import UiForm from '@/components/ui/UiForm';
import UiFormField from '@/components/ui/UiFormField';
import CompanyField from '@/components/form/CompanyField';
import {uploadBuildingBackground, backgroundExists, deleteBackground} from '../api';
import UiButton from "@/components/ui/UiButton";

export default {
  props: {
    building: {
      type: Object,
      required: true
    },
    fileList: [{}]
  },

  data() {
    return {
      buildingBackground: "",
      deleteBackgroundText: "labels.building.delete_background",
      backgroundExists: false,
      backgroundDeleted: false,
      reminder: this.getReminderButtonActive(),
      firstReminder: this.getReminderDays(),
      additionalReminder: this.getAdditionalReminderDays(),
      awesome: false,
      msg: []
    }
  },

  components: {
    UiButton,
    UiForm,
    UiFormField,
    CompanyField
  },

  watch: {
    firstReminder(value) {
      this.firstReminder = value;
      this.validateFirstReminder(value);
    },
    additionalReminder(value) {
      this.additionalReminder = value;
      this.validateAdditionalReminder(value);
    }
  },

  methods: {
    async handleSubmit() {
      if (this.backgroundDeleted) {
        await this.deleteBackground();
      }
      if (this.buildingBackground !== "") {
        await uploadBuildingBackground(this.buildingBackground.raw, this.building.id)
      }

      if (!this.reminder || (this.validateFirstReminder(this.firstReminder) && this.validateAdditionalReminder(this.additionalReminder))) {
        this.building.reminder = this.reminder
        this.building.reminderDays = this.firstReminder;
        this.building.additionalReminderDays = this.additionalReminder;
        this.$emit('submit');
      }

    },

    getReminderDays(){
      return this.building.reminderDays;
    },
    getAdditionalReminderDays(){
      return this.building.additionalReminderDays;
    },
    getReminderButtonActive(){
      return this.building.reminder;
    },
    validateFirstReminder(value) {
      this.validateAdditionalReminder(this.additionalReminder);
      if (value > 60) {
        this.msg['firstReminder'] = this.$t('fields.error_message_more_than_max');
        return false;
      } else if (value <= 0 || Number.isNaN(value)) {
        this.msg['firstReminder'] = this.$t('fields.error_message_less_than_one');
        return false;
      } else {
        this.msg['firstReminder'] = '';
        return true;
      }
    },
    validateAdditionalReminder(value) {
      if (value >= this.firstReminder) {
        this.msg['additionalReminder'] = this.$t('fields.error_message_additional_more_than_first_reminder');
        return false;
      } else if (value < 0 || Number.isNaN(value)) {
        this.msg['additionalReminder'] = this.$t('fields.error_message_less_than_zero');
        return false;
      } else if (value == 0) {
        this.msg['additionalReminder'] = this.$t('fields.error_message_additional_days_zero');
        return true;
      } else {
        this.msg['additionalReminder'] = '';
        return true;
      }
    },
    setPicture(picture) {
      this.buildingBackground = picture;
    },

    handleRemove(file, fileList) {
    },
    handlePreview(file) {
    },
    handleExceed(files, fileList) {
      this.$message.warning(`The limit is 1, you selected ${files.length} file this time, add up to ${files.length + fileList.length} totally`);
    },
    beforeRemove(file) {
      return this.$confirm(`Cancel the transfert of ${file.name} ?`);
    },

    handleCancel() {
      this.$emit('cancel');
    },

    async backgroundExistsFunction() {
      return await backgroundExists(this.building.id);
    },

    setDeletedBackground() {
      this.deleteBackgroundText = "labels.building.deleted"
      this.backgroundDeleted = true
    },

    async deleteBackground() {
      await deleteBackground(this.building.id);
    },
  },

  async mounted() {
    this.backgroundExists = await this.backgroundExistsFunction();
  }

};
</script>

<style scoped>

.el-button--primary {
  background-color: #f3f3f3;
  color: black;
  border-color: #f3f3f3;
  font-weight: 700;
}

.background_form_item {
  display: inline-flex;
}

.delete_button {
  margin-left: 1em;
}


</style>
