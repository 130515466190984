<template>
  <ui-card
    v-loading="loading"
    :heading="`${$t('actions.create')} ${$tc('models.building')}`"
  >
    <building-form
      :building="building"
      @submit="handleSubmit"
      @cancel="handleCancel"
    ></building-form>
  </ui-card>
</template>

<script>
import UiCard from '@/components/ui/UiCard';
import BuildingForm from '../components/BuildingForm';
import { getCompany } from '@/app/companies/api';
import {createBuilding, getBuilding} from '../api';

export default {
  data() {
    return {
      building: {
        name: '',
        description: '',
        company: null
      },
      loading: false
    };
  },

  components: {
    UiCard,
    BuildingForm
  },

  methods: {
    async handleSubmit() {
      this.loading = true;
      try {
        await createBuilding(this.building);
      } catch (err) {
        // this.$message({message: 'nei', type: 'error/success/default => type'})
      }
      this.$router.back();
      this.loading = false;
    },

    handleCancel() {
      this.$router.back();
    },

    async getCompany(id) {
      this.loading = true;
      const company = await getCompany(id);
      this.building.company = company;
      this.loading = false;
    }
  },

  created() {
    if (this.$route.params.companyId) {
      this.getCompany(this.$route.params.companyId);
    }
  },

  mounted() {
    this.$store.commit('changeBreadcrumbs', [
      {name: "models.buildings", link: "/app/buildings/"},
      {name: "actions.create", link: ""}
    ]);
  },
};
</script>
